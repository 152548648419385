import React from "react";
import AvatarImg from "./../assets/imgs/avatar.png";
import UserIcon from "./../assets/icons/user.png";
import EditIcon from "./../assets/icons/edit.png";
import EnvelopeIcon from "./../assets/icons/envelope.png";
import SettingsIcon from "./../assets/icons/settings.png";
import QuestionIcon from "./../assets/icons/question.png";
import LogoutIcon from "./../assets/icons/log-out.png";
import "./../styles/useravatar.css";

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../hooks/ToogleContext";
import useToken from "../hooks/useToken";
import { LightSelector } from "./LightSelectror";

function UserAvatar () {

    const {openUserAvatar, setopenUserAvatar} = React.useContext(ToogleContext);
    const navigate = useNavigate();

    const avatarButtom = () => {
        setopenUserAvatar(!openUserAvatar);
    };

    const handleLogOut = () => {
        localStorage.removeItem('token');
        window.location.reload(true);
    };

    return(
        <div className="avatar">
            <div className="profile" onClick={avatarButtom}>
                <img src={AvatarImg} />
            </div>
            <div className={`avatar-menu ${openUserAvatar ? "avatar-menu": "active"}`}>
                <h3>Jesus Martheyn<br /><span>Dora CEO & Founder</span></h3>
                <div className="avatar-ligth-selector">
                    <LightSelector/>
                </div> 
                <div className="login-profile-items">
                    <li className="login-profile-item">
                        <img src= {UserIcon} /><a href="#">My profile</a>
                    </li>
                    <li className="login-profile-item">
                        <img src= {EditIcon} /><a href="#">Edit profile</a>
                    </li>
                    <li className="login-profile-item"> 
                        <img src= {EnvelopeIcon} /><a href="#">Inbox</a>
                    </li>
                    <li className="login-profile-item">
                        <img src= {SettingsIcon} /><a href="#">Setting</a>
                    </li>
                    <li className="login-profile-item">
                        <img src= {QuestionIcon} /><a href="#">Help</a>
                    </li>
                    <li className="login-profile-item">
                        <img src= {LogoutIcon} /><a onClick={() => { navigate("/"); avatarButtom(); handleLogOut();}}>Logout</a>
                    </li>
                </div>
            </div>
        </div>
        
    );
}

export {UserAvatar};