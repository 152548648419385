import React from "react";
import "./../styles/businessnavbar.scss";

function BusinessNavBar(){
    return(
        <div className ="main-header">
            <a className ="menu-link-main" href="#">All Apps</a>
            <div className ="header-menu">
                <a className ="main-header-link is-active" href="#">Desktop</a>
                <a className ="main-header-link" href="#">Mobile</a>
                <a className ="main-header-link" href="#">Web</a>
            </div>
        </div>
    );
}

export {BusinessNavBar};