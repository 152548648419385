import React from "react";
import "./../../styles/businessnavbar.scss";

function FinancialsNavBar(){
    return(
        <div className ="main-header">
            <a className ="menu-link-main" href="#">All Apps</a>
            <div className ="header-menu">
                <a className ="main-header-link" href="#">Summary</a>
                <a className ="main-header-link" href="#">Quotes</a>
                <a className ="main-header-link" href="#">Billing</a>
                <a className ="main-header-link is-active" href="#">Payments</a>
            </div>
        </div>
    );
}

export {FinancialsNavBar};