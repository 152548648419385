import React from "react";
import "./../../styles/businessnavbar.scss";

function LogisticNavBar(){
    return(
        <div className ="main-header">
            <a className ="menu-link-main" href="#">All Apps</a>
            <div className ="header-menu">
                <a className ="main-header-link" href="#">Summary</a>
                <a className ="main-header-link is-active" href="#">Importations</a>
                <a className ="main-header-link" href="#">Exportations</a>
            </div>
        </div>
    );
}

export {LogisticNavBar};