import React, {useState} from 'react';

import Login from './pages/Login';
import { Dashboard } from "./pages/Dashboard";
import { Inventory } from "./pages/Inventory/Inventory";
import { Purchase } from "./pages/Purchase/Purchase";
import { Sales } from "./pages/Sales/Sales";
import { Financials } from "./pages/Financials/Financials";
import { Logistic } from "./pages/Logistic/Logistic";


import { ToogleProvider } from "./hooks/ToogleContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter ([
  {
    path: "/",
    element: <Dashboard/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/inventory",
    element: <Inventory/>,
  },
  {
    path: "/purchase",
    element: <Purchase/>,
  },
  {
    path: "/sales",
    element: <Sales/>,
  },
  {
    path: "/logistic",
    element: <Logistic/>,
  },
  {
    path: "/financials",
    element: <Financials/>,
  },
]);

function App() {
  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
  );
}

export default App;
