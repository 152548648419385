import React from "react";
import "./../styles/lightselector.css";

import { ToogleContext } from "../hooks/ToogleContext";

function LightSelector(){
    const {isDarklight, setDarklight} = React.useContext(ToogleContext);

    const changeLightMode = () => {
        setDarklight(!isDarklight);
        document.body.classList.toggle('light-mode');
    }

    return(
        <div class="light-selector-container">
            <input type="checkbox" name="checkbox" className="switch-ligth-selector" onClick={changeLightMode}/>
        </div>
    );
}

export {LightSelector};