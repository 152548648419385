import React from 'react';
import logo from "./../../assets/imgs/Dora_logo.png";

import Login from '../../components/Login';
import { MainNavBar } from '../../components/MainNavBar';
import { SearchBar } from '../../components/SearchBar';
import { NotificationNumber } from '../../components/NotificationNumber';
import { UserAvatar } from '../../components/UserAvatar';
import { LeftSideBar } from '../../components/LeftSideBar';
import { LogisticNavBar } from '../../components/Logistic/LogisticNavBar';
import { MainContent } from '../../components/MainContent';
import { MainGrid } from '../../components/MainGrid';
import { BlogComponent } from '../../components/BlogComponent';

import "./../../styles/PageFrame.scss";

import useToken from "../../hooks/useToken";

function Logistic() {

  const {token, setToken} = useToken();

  if (!token){
    return <Login 
    // setToken={setToken}
    />
  }

  return (
    <React.Fragment>
        <div className="page-frame-container">
            <div className ="app">
                <div className ="header">
                    <div className="app-logo">
                        <img src={logo} alt= "logo Dora"/>
                    </div>
                    {/* <div className ="menu-circle"></div> */}
                    <div className ="header-menu">
                        <MainNavBar/>
                    </div>
                    <div className ="search-bar">
                        <SearchBar/>
                    </div>
                    <div className ="header-profile">
                        <div className ="notification">
                            <NotificationNumber/>
                        </div>
                        <svg viewBox="0 0 512 512" fill="currentColor">
                            <path d="M448.773 235.551A135.893 135.893 0 00451 211c0-74.443-60.557-135-135-135-47.52 0-91.567 25.313-115.766 65.537-32.666-10.59-66.182-6.049-93.794 12.979-27.612 19.013-44.092 49.116-45.425 82.031C24.716 253.788 0 290.497 0 331c0 7.031 1.703 13.887 3.006 20.537l.015.015C12.719 400.492 56.034 436 106 436h300c57.891 0 106-47.109 106-105 0-40.942-25.053-77.798-63.227-95.449z" />
                        </svg>
                        <div className="profile-img">
                            <UserAvatar/>
                        </div>
                    </div>
                </div>
                <div className ="wrapper">
                    <div className ="left-side">
                        <LeftSideBar/>
                    </div>
                    <div className ="main-container">
                        <LogisticNavBar/>
                        <div className ="content-wrapper">
                            <div className ="content-wrapper-header">
                                <MainContent/>
                            </div>
                            <div className ="content-section">
                                <div className ="content-section-title">Installed</div>
                                <MainGrid/>
                            </div>
                            <div className ="content-section">
                                <div className ="content-section-title">Apps in your plan</div>
                                {/* <BlogComponent/> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-app"></div>
            </div>
        </div>
    </React.Fragment>
  );
}

export {Logistic};
