import React from "react";
import { useNavigate } from "react-router-dom";

function MainNavBar(){

    const navigate = useNavigate();

    return(
        <React.Fragment>
            <a className ="menu-link is-active" onClick={() => navigate("/")}>Dashboard</a>
            <a className ="menu-link notify" onClick={() => navigate("/inventory")}>Inventory</a>
            <a className ="menu-link" onClick={() => navigate("/financials")}>Financials</a>
            <a className ="menu-link" onClick={() => navigate("/purchase")}>Purchase</a>
            <a className ="menu-link notify" onClick={() => navigate("/sales")}>Sales</a>
            <a className ="menu-link notify" onClick={() => navigate("/logistic")}>Logistic</a>
        </React.Fragment>
    );
}

export {MainNavBar};