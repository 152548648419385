import React, { useState } from 'react';
// import { useLocalStorage } from './useLocalStorage';

const ToogleContext = React.createContext();

function ToogleProvider(props) {

// const { languague: defaultLanguage} = useLocalStorage('APP_VARS_V1', []);
const [token, setToken] = useState();
const [appLanguage, setappLanguage] = React.useState("Español");
const [openUserAvatar, setopenUserAvatar] = React.useState(true);
const [isDarklight, setDarklight] = React.useState(true);
const [scrollTop, setscrollTop] = React.useState(false);


return (
    <ToogleContext.Provider value={{
      token,
      setToken,
      appLanguage,
      setappLanguage,
      openUserAvatar,
      setopenUserAvatar,
      isDarklight,
      setDarklight,
      scrollTop,
      setscrollTop,
    }}>
      {props.children}
    </ToogleContext.Provider>
  );

  }

export { ToogleContext, ToogleProvider };